export const LOADING = (state, payload) => {
  state.loading = payload;
};
export const LOADING_LAYER = (state, payload) => {
  state.loadingLayer = payload;
};
export const WINDOW_FOCUS_STATE = (state, payload) => {
  state.windowFocusState = payload;
};

