export const SET_REPORT_YEAR = (state, data) => {
  state.report.report_year = data; }; export const SET_REPORT_PERIOD = (state, data) => {
  state.report.report_period = data;
};
export const SET_TAX_REFUND_PROPOSAL = (state, data) => {
  state.tax_refund_proposal = data;
};
export const SET_SUBMIT_REPORT = (state, data) => {
  state.submit_report = data;
};
export const SET_VALUE_REFUND_PROPOSAL = (state, value) => {
  state.value_refund_proposal = value;
};
export const SET_IS_MANUAL = (state, isManual) => {
  state.is_manual = isManual;
};
export const SET_IS_REPORT_LOADING = (state, isReportLoading) => {
  state.is_report_loading = isReportLoading;
};
export const SET_IS_DOWNLOADABLE = (state, is_downloadable) => {
  state.is_downloadable = is_downloadable;
};
export const SET_IS_DOWNLOAD_PROCESSING = (state, is_download_processing) => {
  state.is_download_processing = is_download_processing;
};
export const SET_IS_LARGE_PDF = (state, is_large_pdf) => {
  state.is_large_pdf = is_large_pdf;
};
