export const SET_COMPANIES = (state, companies) => {
  state.companies = companies;
};
export const SET_SELECTED_COMPANY = (state, company) => {
  if (company.id > -1) {
    company.text = `${company.tenDoanhNghiep} - ${company.mst}`
    if (company.owner) {
      company.owner["roles"] = [{ role__name: "Owner" }];
      company.owner['is_owner'] = true
    }
  }

  state.selectedCompany = company;
};
