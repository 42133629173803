export const getProfileCacheDigest = (state) => {
  return state.profile;
};
export const getDashboard = (state) => {
  return state.dashboard;
};

export const getCompanyTotalInvoices = (state, getters) => {
  const company_id = getters['getSelectedCompany']['id']
  if (state.dashboard && state.dashboard[company_id]) {
    return state.dashboard[company_id]['total_invoice']
  }

  return 0
};
