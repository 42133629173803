export const setInvoiceKeySearch = ({ commit }, keySearch) => {
  commit("SET_INVOICE_KEY_SEARCH", keySearch);
};
export const setActiveInvoice = ({ commit }, sort) => {
  commit("SET_ACTIVE_INVOICE", sort);
};
export const setInvoiceSort = ({ commit }, sort) => {
  commit("SET_INVOICE_SORT", sort);
};
export const setInvoicePaginate = ({ commit }, sort) => {
  commit("SET_INVOICE_PAGINATE", sort);
};
export const resetInvoicePaginatePage = ({ commit }) => {
  commit("RESET_INVOICE_PAGINATE_PAGE");
};


