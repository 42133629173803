<template>
  <div class="progress" :class="{ loading: showLoadingLayer }">
    <span id="progress-bar"> </span>
    <div class="loading-layer">
      <loading-invoice class="dots" v-if="showLoadingLayer" />
    </div>
  </div>
</template>

<script>
import loadingInvoice from "@/components/loadingInvoice";
export default {
  components: {
    loadingInvoice,
  },
  name: "Loading",
  data() {
    return {
      idInterval: null,
      timer: null,
      DELAY: 700,
    };
  },
  computed: {
    showLoading() {
      return this.$store.getters.loading;
    },
    showLoadingLayer() {
      return this.$store.getters.loadingLayer;
    },
  },
  methods: {
    loadingAnimation(elem) {
      elem.style.visibility = "visible";
      let width = 0;
      clearInterval(this.idInterval);
      this.idInterval = setInterval(frame, 50);
      function frame() {
        if (width < 90) {
          width += 10;
          elem.style.width = `${width}%`;
        }
      }
    },
    clearAnimation(elem) {
      clearInterval(this.idInterval);
      elem.style.width = `100%`;
      setTimeout(() => {
        elem.style.visibility = "hidden";
        elem.style.width = "0%";
      },  this.DELAY + 400); // delay + wait (500 + 400)
    },
  },
  watch: {
    showLoading(newVal) {
      let elem = document.getElementById("progress-bar");
      if (!newVal) {
        clearTimeout(this.timer);
        this.clearAnimation(elem);
      } else {
        this.timer = setTimeout(() => {this.loadingAnimation(elem)}, this.DELAY)
      }
    },
  },
};
</script>

<style scoped>
.progress {
  position: absolute;
  width: 100%;
  height: 1px;
  background: transparent;
  border-radius: 3px;
  overflow: hidden;
}
#progress-bar {
  position: fixed;
  display: block;
  height: 1px;
  width: 0%;
  background: linear-gradient(to right, #86c2ff 0%, #375777 100%);
  background-size: 300% 100%;
  transition: width 0.3s;
  z-index: 3001;
}
.loading-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loading {
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5000;
  position: absolute;
}

/* Loading long progress */
.dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #409eff;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* End loading long progress*/
</style>
