export const setInvoices = ({ commit }, invoices) => {
  commit("SET_INVOICES", invoices);
};
export const setDetailInvoice = ({ commit }, invoice) => {
  commit("SET_DETAIL_INVOICE", invoice);
};
export const setInvoiceCache = ({ commit }, invoice) => {
  commit("SET_CACHE_INVOICES", invoice);
};

