export const getAuthUser = (state) => {
  return state.authUser
};
export const getExpiredAt = (state) => {
  return state.expiredAt;
};
export const getToken = (state) => {
  return state.token;
};

export const checkPermissionAbleTo = (state, permission_code) => {
  if (state.authUser && state.authUser.permissions) {
    return state.authUser.permissions.indexOf(permission_code) != -1;
  }
  return false
};
