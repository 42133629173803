<template>
  <div id="nav" class="text-center relative"></div>
  <Loading />
  <router-view />
</template>

<script>
import Loading from "@/components/Loading";
export default {
  name: "App",
  components: {
    Loading,
  },
};
</script>
<style>
.el-table thead {
  color: #303133 !important;
}
.el-tabs__item.is-active {
  color: white !important;
  background: #409eff;
  border-radius: 2px;
  padding: 1.5px 15px !important;
  height: 30px;
  line-height: 24.5px !important;
}
.el-tabs__item {
  line-height: unset !important;
  border-radius: 2px;
  padding: 1.5px 15px !important;
}
.itax-button .el-button--mini {
  padding: 7px 15px !important;
  font-size: 14px;
}
.el-tabs__nav.is-top {
  height: 35px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #409eff !important;
}
.select2-search__field {
  height: 28px;
  outline: none;
}
.swal2-styled.swal2-confirm {
  background-color: #409eff !important;
}
/* width */
body::-webkit-scrollbar {
  width: 6px;
}
/* Track */
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}
/* Handle */
body::-webkit-scrollbar-thumb {
  background: #909399;
  border-radius: 10px;
}
/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
.select2-results__option {
  font-size: 0.9rem !important;
}
.el-table__cell {
  padding: 5px 0 !important;
}
.el-table__header .el-table__cell {
  padding: 12px 0 !important;
}
.el-table .cell {
  word-break: break-word !important;
}
.el-scrollbar {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
