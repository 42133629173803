import { createI18n } from "vue-i18n/index";
import en from "./en.json";
import vi from "./vi.json";
import ja from "./ja.json";

const messages = {
  en: en,
  vi: vi,
  ja: ja,
};
const i18n = new createI18n({
  locale: "vi",
  messages,
  fallbackLocale: "vi",
});

export default i18n;
