export default {
  report: {
    report_year: null,
    report_period: null,
  },
  submit_report: {},
  tax_refund_proposal: {},
  value_refund_proposal: null,
  is_manual: false,
  is_report_loading: false,
  is_downloadable: false,
  is_download_processing: false,
  is_large_pdf: false,
};
