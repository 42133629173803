export default {
  activeInvoice: "all",
  keySearch: "",
  sort: {},
  paginate: {
    limit: 20,
    currentPage: 1,
    cursor: null,
    next: null,
    prev: null,
  },
};
