export const getReportPeriod = (state) => {
  return state.report.report_period; };
export const getReportYear = (state) => {
  return state.report.report_year;
};
export const getSubmitReport = (state) => {
  return state.submit_report;
};
export const getTaxRefundProposal = (state) => {
  return state.tax_refund_proposal;
};
export const getValueRefundProposal = (state) => {
  return state.value_refund_proposal;
};
export const getIsManual = (state) => {
  return state.is_manual;
};
export const getIsReportLoading = (state) => {
  return state.is_report_loading;
};
export const getIsDownloadable = (state) => {
  return state.is_downloadable;
};
export const getIsDownloadProcessing = (state) => {
  return state.is_download_processing;
};
export const getIsLargePDF = (state) => {
  return state.is_large_pdf;
};
