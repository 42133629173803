export const SET_INVOICE_LOG_SEARCH = (state, paginate) => {
  state.paginate = paginate;
};

export const RESET_INVOICE_LOG_SEARCH_PAGE = (state) => {
  state.paginate = {
    ...state.paginate,
    currentPage: 1,
  }
};
