export const SET_INVOICE_KEY_SEARCH = (state, keySearch) => {
  state.keySearch = keySearch;
};
export const SET_ACTIVE_INVOICE = (state, activeInvoice) => {
  state.activeInvoice = activeInvoice;
};
export const SET_INVOICE_SORT = (state, sort) => {
  state.sort = sort;
};
export const SET_INVOICE_PAGINATE = (state, paginate) => {
  state.paginate = paginate;
};
export const RESET_INVOICE_PAGINATE_PAGE = (state) => {
  state.paginate = {
    ...state.paginate,
    currentPage: 1,
  }
};

