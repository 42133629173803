export const setreportYear = ({ commit }, data) => {
  commit("SET_REPORT_YEAR", data);
};
export const setReportPeriod = ({ commit }, data) => {
  commit("SET_REPORT_PERIOD", data);
};
export const setTaxRefundProposal = ({ commit }, data) => {
  commit("SET_TAX_REFUND_PROPOSAL", data);
};
export const setSubmitReport = ({ commit }, data) => {
  commit("SET_SUBMIT_REPORT", data);
};
export const setValueRefundProposal = ({ commit }, value) => {
  commit("SET_VALUE_REFUND_PROPOSAL", value);
};
export const setIsManual = ({ commit }, isManual) => {
  commit("SET_IS_MANUAL", isManual);
};
export const setIsReportLoading = ({ commit }, isReportLoading) => {
  commit("SET_IS_REPORT_LOADING", isReportLoading);
};
export const setIsDownloadable = ({ commit }, isDownloadable) => {
  commit("SET_IS_DOWNLOADABLE", isDownloadable);
};
export const setIsDownloadProcessing = ({ commit }, isDownloadProcessing) => {
  commit("SET_IS_DOWNLOAD_PROCESSING", isDownloadProcessing);
};
export const setIsLargePDF = ({ commit }, isIsLargePDF) => {
  commit("SET_IS_LARGE_PDF", isIsLargePDF);
};
