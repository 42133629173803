export const SET_GOV_INVOICE_HISTORY_PAGINATE = (state, paginate) => {
  state.paginate = paginate;
};
export const SET_GOV_INVOICE_HISTORY_DIGEST = (state, digest) => {
  state.digest = digest;
};

export const RESET_GOV_INVOICE_HISTORY_SEARCH_PAGE = (state) => {
  state.paginate = {
    ...state.paginate,
    currentPage: 1,
  }
};
