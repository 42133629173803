// JWT
export const setToken = ({ commit }, token) => {
  commit("SET_TOKEN", token);
};
export const setExpiredAt = ({ commit }, expiredAt) => {
  commit("SET_EXPIRED_AT", expiredAt);
};
// End auth

export const setAuthUser = ({ commit }, user) => {
  commit("SET_AUTH_USER", user);
};

