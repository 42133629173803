import { createApp } from "vue";
import router from "./router";
import store from "./store";
import App from "./App.vue";

import "./tailwind.css";
import "@/plugins/swal.css";

import i18n from "./plugins/i18n";
import axios from "./plugins/axios";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import "./global.css";
const swalGlobalConfig = {
  icon: "success",
  timer: "3000",
  width: "450",
  padding: "10px",
};

const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(i18n)
  .use(ElementPlus)
  .use(axios)
  .use(VueSweetalert2, swalGlobalConfig)
  .mount("#app");
