export const SET_KEY_SEARCH_MAIL_BOX = (state, keySearch) => {
  state.key_search = keySearch;
};
export const SET_MAIL_BOX_PAGINATE = (state, paginate) => {
  state.paginate = paginate;
};
export const RESET_MAIL_BOX_PAGINATE_PAGE = (state) => {
  state.paginate = {
    ...state.paginate,
    currentPage: 1,
  }
};

