import axios from "axios";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
var is_refeshing_token = false;

export default {
  install: (app) => {
    app.config.globalProperties.$request = async (option) => {
      const request = axios.create({
        baseURL:
          (option && option.baseURL) ||
          `${process.env.VUE_APP_BACK_END_URL}/api/`,
      });

      let times = 0;
      while (is_refeshing_token && times <= 100) {
        await delay(50);
        times++;
      }

      const store = app.config.globalProperties.$store;
      const router = app.config.globalProperties.$router;
      const swal = app.config.globalProperties.$swal;
      embedTokenToRequest(request, store);
      updateCacheDigestOnResourceEdit(store, option)
      const params = embedCacheDigest(store, option);
      refreshToken(request, store);

      try {
        if (!option.isHideLoading) {
          store.dispatch("loading", true);
        }
        // console.log(`[DEBUG]: ${option.method || "GET"}: ${option.url}`);

        // Auto add company_id to input For PERMISSION check
        const method = option.method || "GET";
        const data = option.data || {};
        if (method.toUpperCase() == "GET" && !params["company_id"]) {
          params["company_id"] = store.getters["getSelectedCompany"].id;
        }

        let response = await request({
          responseType: option.responseType,
          url: option.url,
          method,
          data,
          params,
          timeout: option.timeout,
        });
        const res = response.data;
        if (!option.isHideLoading) {
          store.dispatch("loading", false);
        }

        if (res.code != 200) {
          store.dispatch("setIsUpdating", false);
        }

        // Unauthenticated
        if (res.code == 403) {
          store.dispatch("setToken", null);
          router.push("/login");
        }

        // Unauthorized => redirect companyManagement
        if (res.code == 401 && !option.ignore401) {
          swal({
            toast: true,
            title: "Limited access. Please contact Owner",
            width: "330px",
            icon: "error",
            position: "top",
            showConfirmButton: false,
            timerProgressBar: true,
          });
        }

        // NotFound => Ignore
        if (res.code == 404);

        return response;
      } catch (e) {
        if (e.code === "ECONNABORTED") {
          throw e;
        }
        const error = e.response || "";
        return error;
      } finally {
        store.dispatch("loading", false);
      }
    };
  },
};

function embedTokenToRequest(request, store) {
  request.interceptors.request.use((config) => {
    config.headers["Authorization"] = "Bearer " + store.getters["getToken"];
    config.headers["Content-Type"] = "application/json";
    return config;
  });
}

function embedCacheDigest(store, option) {
  const params = option.params || {};
  // params['v'] = store.getters["getExpiredAt"];
  params["v"] = store.getters["getProfileCacheDigest"];
  return params;
}

function updateCacheDigestOnResourceEdit(store, option) {
  const isResourceChange = (option.method || "GET") != "GET";
  if (isResourceChange && option.url.indexOf("online") == -1 && store.getters["getToken"]){
    store.dispatch("updateProfileCacheDigest");
  }
}

async function refreshToken(request, store) {
  const expired_at = store.getters["getExpiredAt"];
  const remainTime = new Date(`${expired_at}+00:00`) - new Date();

  if (expired_at && remainTime <= 900000) {
    // call api refresh token
    let result = await request({
      url: `user/refresh-token`,
      method: "POST",
    });
    const res = result.data;
    if (res.code == 200 && res.success) {
      // refresh token
      store.dispatch("setToken", res.data.token);
      store.dispatch("setExpiredAt", res.data.expired_at);
    }
  }
}
