<template>
  <el-tooltip
    class="item"
    effect="dark"
    :content="textContent"
    placement="left"
  >
    <div id="loading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    textContent: {
      type: String,
      default: "Hệ thống đang xử lý hóa đơn...",
    },
  },
};
</script>

<style scoped>
#loading {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 1.5rem;
  margin-right: 0.5rem;
}

span {
  width: 0.2em;
  height: 0.75rem;
  background-color: #3689ff;
}

span:nth-of-type(1) {
  animation: grow 1s -0.45s ease-in-out infinite;
}

span:nth-of-type(2) {
  animation: grow 1s -0.3s ease-in-out infinite;
}

span:nth-of-type(3) {
  animation: grow 1s -0.15s ease-in-out infinite;
}

span:nth-of-type(4) {
  animation: grow 1s ease-in-out infinite;
}

@keyframes grow {
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
}
</style>
