export const getActiveInvoice = (state) => {
  return state.activeInvoice;
};
export const getInvoiceSort = (state) => {
  return state.sort;
};
export const getInvoicePaginate = (state) => {
  return state.paginate;
};
export const getInvoiceKeySearch = (state) => {
  return state.keySearch;
};
